<template>
  <div class="container">
    <navbar></navbar>
    <div class="content t-white px-15">
      <p class="bold fs-xxl t-center">{{ $t("home.cardStore") }}</p>
      <!-- 卡牌等级选择 -->
      <div class="select borders fs-lg d-flex ai-center">
        <div class="left t-center">{{ $t("store.level") }}</div>
        <van-popover
          v-model="showLevel"
          trigger="click"
          :actions="columns"
          theme="dark"
          @select="onConfirm"
          class="flex-1"
        >
          <template #reference>
            <div class="d-flex ai-center jc-center">
              <span>{{ columns[defaultIndex].text }}</span>
              <van-image
                width="10px"
                height="8px"
                class="m-l-5"
                :src="require('@/assets/arrow@2x.png')"
              />
            </div>
          </template>
        </van-popover>
      </div>
      <!-- 卡牌列表 -->
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          @load="load"
        >
          <div class="d-flex flex-wrap jc-between px-15">
            <div
              v-for="(item, index) in list"
              :key="index"
              @click="toCardDetail(item)"
              class="card_item m-b-20"
            >
              <van-image
                width="100%"
                height="210px"
                :src="require('@/assets/gif/border' + item.level + '.png')"
              />
              <van-image
                width="100%"
                height="210px"
                class="img p-5"
                :src="item.cardInfo.remote_url"
              />
              <p class="fs-md d-flex ai-center jc-between m-t-5">
                <span>{{
                  lang == "zh" ? item.cardInfo.name : item.cardInfo.en_name
                }}</span
                ><span class="t-color5">{{ item.price | cutZero }} BNB</span>
              </p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],

      showLevel: false,
      defaultIndex: 0,
      isselect: false,
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
    columns() {
      return [
        { index: 0, text: this.$t("public.all") },
        { index: 1, text: this.$t("level.level1") },
        { index: 2, text: this.$t("level.level2") },
        { index: 3, text: this.$t("level.level3") },
        { index: 4, text: this.$t("level.level4") },
        { index: 5, text: this.$t("level.level5") },
      ];
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    onConfirm(e) {
      this.isselect = true;
      this.totalPage = 1;
      this.pageNumber = 1;
      this.defaultIndex = e.index;
      this.list = [];
      this.loading = true;
      this.getList(e.index == 0 ? "" : e.index);
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    load() {
      setTimeout(() => {
        if (!this.isselect) {
          this.finished = false;
          this.getList(this.defaultIndex == 0 ? "" : this.defaultIndex);
          this.pageNumber++;
        } else {
          this.finished = false;
          this.pageNumber++;
          this.getList(this.defaultIndex == 0 ? "" : this.defaultIndex);
        }
      }, 500);
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList(this.defaultIndex == 0 ? "" : this.defaultIndex);
      }, 1000);
    },
    getList(level) {
      let that = this;
      // this.loading = true;
      this.$axios
        .get(this.$api.mallCards, {
          level: level,
          page: this.pageNumber,
          page_size: 8,
          sort_type: 1,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            that.loading = false;
            that.refreshing = false;
            let arr = res.data.list;
            that.totalPage = res.data.totalPage; // 总条数
            if (arr == null || arr.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            that.list.push(...arr);
            // 如果当前页数>=总条数，不再触发滚动加载
            if (that.pageNumber >= that.totalPage) {
              that.finished = true;
            }
          } else {
            that.loading = false;
            that.$toast(res.msg);
          }
        })
        .catch((err) => {
          that.loading = false;
        });
    },
    //跳转卡片详情
    toCardDetail(item) {
      let value = JSON.stringify(item);
      this.$router.push({
        path: "/cardDetail",
        query: { value: value },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-grid-item__content {
  background-color: transparent;
  padding: 10px 0;
}
.select {
  min-width: 158px;
  max-width: 178px;
  border-image: linear-gradient(#0d182a, #0d182a),
    linear-gradient(21deg, #15a3ee, #391aeb);
  line-height: 24px;
  border-radius: 12px;
  margin: 20px auto;
  .left {
    width: 50%;
    border-right: 1px solid #00a1ff;
  }
}
.card_item {
  width: 47%;
  position: relative;
  .img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>